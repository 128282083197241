<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.name" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <!--查看详情表单组件-->
    <detailForm ref="detailForm" />
    <!--调价表单组件-->
    <applyForm ref="applyForm" />
    <!-- 表格 -->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="code" label="商品编码" min-width="130" />
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
      <el-table-column prop="name" label="商品名称" min-width="240" />
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
      <el-table-column prop="brandName" label="品牌" width="160" />
      <el-table-column prop="categoryName" label="类目" width="160" />
      <el-table-column prop="seriesName" label="系列" width="160" />
      <el-table-column label="统一零售价" width="120" :formatter="v=>{return '￥'+(v.retailPrice/100).toFixed(2)}" align="right" />
      <el-table-column label="价格详情" width="120" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.goodsPrice" size="mini" type="text" @click="detail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
      <el-table-column width="50" align="center" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" @click="adjustPrice(scope.row)">调价</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import request from "@/utils/request";
import detailForm from "./form"; //查看详情弹框
import applyForm from "./apply"; //调价弹框

export default {
  components: { detailForm, applyForm },
  mixins: [initData, initDict],
  created() {
    this.getDictMap("enterprise_type,distributor_level").then((_) => {
      this.init();
    });
  },
  data() {
    return {
      query: {
        name: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/distributorGoodsPrice/all";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "name,asc" },
        this.query
      );
      return true;
    },
    detail(data) {
      request({
        url:
          "api/distributorGoodsPriceDetail/" +
          data.goodsId +
          "?entId=" +
          data.entId,
        method: "get",
      })
        .then((res) => {
          this.$refs.applyForm.dialog = true;
          this.$refs.applyForm.editable = false;

          this.$refs.applyForm.getDealerData(
            data.retailPrice,
            data.goodsId,
            res
          );
        })
        .catch((err) => {});
    },
    adjustPrice(data) {
      this.$refs.applyForm.editable = true;
      this.$refs.applyForm.getDealerData(data.retailPrice, data.goodsId);
      this.$refs.applyForm.dialog = true;
    },
  },
};
</script>